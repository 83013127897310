<template>
  <section> 
    <div class="demo-inline-spacing">
      <b-form-checkbox v-model="showNotes">
        <b>Show Notes</b>
      </b-form-checkbox>
      <b-form-checkbox v-model="showUpdateActivity">
        <b>Show Update Activity</b>
      </b-form-checkbox>
      <b-form-checkbox v-model="showPublishingActivity">
        <b>Show Publishing Activity</b>
      </b-form-checkbox>
    </div>
    <br>
    <b-card>
      <app-timeline>  
        <app-timeline-item 
          v-for="(item, index) in filteredHistory"  :key="index"
          :icon="iconForHistoryItem(item)"
        >
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>{{ textForHistoryItem(item) }}</h6>
            <small class="text-muted">{{ item.timestamp | formatISODate }}</small>
          </div>
          <div v-if="item.user">{{ item.user.first_name }} {{ item.user.last_name }}</div>
          <div v-if="item.type === 'notes'">
            <b-card bg-variant="light"> 
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div v-html="$sanitize(item.notes)"></div>
            </b-card> 
          </div>
        </app-timeline-item>
      </app-timeline>
    </b-card>
  </section>
</template>
 
<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import {
  BCard, BFormCheckbox
} from 'bootstrap-vue'

export default {
 
  components: {
    BCard, 
    BFormCheckbox,
    AppTimeline,
    AppTimelineItem,
  },

  props: {
    article: {
      type: Object,
      default: null
    },    
    allHistory: {
      type: Array,
      default: null
    },  
  },
  data () {
    return {
      showUpdateActivity: true,
      showPublishingActivity: true,
      showNotes: true,
      filteredHistory: null,
    }
  },
  computed: {

  },
  watch: {
    showUpdateActivity () {
      this.filterTimeline()
    },
    showPublishingActivity () {
      this.filterTimeline()
    },
    showNotes () {
      this.filterTimeline()
    },
  },
  mounted () {
    this.filterTimeline()
  },

  methods: {
    filterTimeline () {
      const tmp = []
      for (let i = 0; i < this.allHistory.length; i++) {
        const rec = this.allHistory[i]
        if (rec.type === 'notes' && this.showNotes) {
          tmp.push(rec)
        } else if (rec.type === 'staging' && this.showPublishingActivity) {
          tmp.push(rec)
        } else if (rec.type === 'changes' && this.showUpdateActivity) {
          tmp.push(rec)
        }
        //console.log(rec.type, this.showNotes, this.showPublishingActivity, this.showUpdateActivity) 
      }
      this.filteredHistory = tmp
    },
    iconForHistoryItem (item) {
      if (item.type === 'notes') {
        return 'FileTextIcon'
      }
      if (item.type === 'staging') {
        if (item.status === 'Reopened in Production') {
          return 'LayoutIcon'
        } 
        if (item.status === 'Moved To Published') {
          return 'ArchiveIcon'
        } 
        if (item.status === 'in staging') {
          return 'ExternalLinkIcon'
        } 
        if (item.status === 'in transit') {
          return 'ExternalLinkIcon'
        } 
        return 'CircleIcon'
      }
      if (item.changeActivityName === 'File Uploaded') {
        return 'UploadCloudIcon'
      } 
      return 'CircleIcon'
    },
    textForHistoryItem (item) {
      if (item.type === 'notes') {
        return 'Note added'
      }
      if (item.type === 'staging') {
        if (item.status === 'Reopened in Production') {
          return item.status
        } 
        if (item.status === 'Moved To Published') {
          return item.status
        } 
        if (item.status === 'in staging') {
          return 'Staged on Web Host'
        } 
        if (item.status === 'in transit') {
          return 'Staging Commenced'
        }
        return item.status
      }
      return item.changeActivityName
    },
  }
}
</script>

import { render, staticRenderFns } from "./ArticleOperations.vue?vue&type=template&id=b2481c2c&"
import script from "./ArticleOperations.vue?vue&type=script&lang=js&"
export * from "./ArticleOperations.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
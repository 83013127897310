<template>
  <section> 
    <b-tabs        
      vertical
      end
      nav-wrapper-class="nav-vertical"
    >
      <b-tab title="Summary">
        <br>
        <article-detail-xml-summary
          :article="article" 
          :article-summary="articleSummary"
          :literatum-schematron-errors="literatumSchematronErrors"
          :generic-schematron-errors="genericSchematronErrors"
          @UpdateAuditRequested="updateAudit"
        />
      </b-tab> 
      <b-tab v-if="articleSummary.relatedArticles.length > 0" title="Related Articles">
        <br>
        <b-card>
          <span v-for="(xml, index) in articleSummary.relatedArticles"  :key="index">
            <b-row>
              <b-col cols="12">{{ xml }}</b-col>
            </b-row>
            <br>
          </span>
        </b-card>
      </b-tab>

      <b-tab title="Contributors" lazy>
        <br>
        <article-detail-contributors
          :article="article" 
          :article-summary="articleSummary"
          :literatum-schematron-errors="literatumSchematronErrors"
          :generic-schematron-errors="genericSchematronErrors"
          @UpdateAuditRequested="updateAudit"
        />
      </b-tab>

      <b-tab title="Abstract" lazy>
        <div class="demo-inline-spacing">
        </div>
        <br> 

        <div v-for="(abstract, index) in article['abstract']"  :key="index">
          <b-card-actions :title="'Abstract '.concat(abstract.type)" title-color="primary" action-collapse>
            <b-form-textarea  :value="abstract"  :rows="textAreaRowsOn(abstract)" />
          </b-card-actions>
          
          <br>

        </div>
      </b-tab>

      <b-tab title="Tags" lazy>
        <br>
        <article-detail-xml-tags
          :article="article" 
          :article-summary="articleSummary"
          :literatum-schematron-errors="literatumSchematronErrors"
          :generic-schematron-errors="genericSchematronErrors"
          @UpdateAuditRequested="updateAudit"
        />
      </b-tab>

      <b-tab title="Funding">
        <br>
        <div  v-for="(parent, index) in article['funding']"  :key="index">
          <b-card-actions :title="parent.tag" title-color="primary" action-collapse>
            <div  v-for="(snippet, index2) in parent.child_tags"  :key="index2"> 
              <b-form-textarea v-if="parent.tag === snippet.tag" v-model="snippet.child_tags"  :rows="textAreaRowsOn(snippet.child_tags)" />
              <b-card-actions  v-else :title="snippet.tag" title-color="primary" action-collapse>
                <b-form-textarea v-model="snippet.child_tags"  :rows="textAreaRowsOn(snippet.child_tags)" />
              </b-card-actions>
              <br>
            </div>
          </b-card-actions>
          <br>
        </div>
      </b-tab>

      <b-tab title="Permission">
        <br>
        <div  v-for="(parent, index) in article['permission']"  :key="index">
          <b-card-actions :title="parent.tag" title-color="primary" action-collapse>
            <div  v-for="(snippet, index2) in parent.child_tags"  :key="index2"> 
              <b-form-textarea v-if="parent.tag === snippet.tag" v-model="snippet.child_tags"  :rows="textAreaRowsOn(snippet.child_tags)" />
              <b-card-actions  v-else :title="snippet.tag" title-color="primary" action-collapse>
                <b-form-textarea v-model="snippet.child_tags"  :rows="textAreaRowsOn(snippet.child_tags)" />
              </b-card-actions>
              <br>
            </div>
          </b-card-actions>
          <br>
        </div>
      </b-tab>
      
      <b-tab v-if=" article['other front']" title="Other Front">
        <br>
        <div  v-for="(parent, index) in article['other front']"  :key="index">
          <b-card-actions :title="parent.tag" title-color="primary" action-collapse>
            <div  v-for="(snippet, index2) in parent.child_tags"  :key="index2"> 
              <b-form-textarea v-if="parent.tag === snippet.tag" v-model="snippet.child_tags"  :rows="textAreaRowsOn(snippet.child_tags)" />
              <b-card-actions  v-else :title="snippet.tag" title-color="primary" action-collapse>
                <b-form-textarea v-model="snippet.child_tags"  :rows="textAreaRowsOn(snippet.child_tags)" />
              </b-card-actions>
              <br>
            </div>
          </b-card-actions>
          <br>
        </div>
      </b-tab>

      <b-tab title="Body" lazy>
        <br>
        <b-card> 
          <b-form-textarea v-model="article.body" :rows="textAreaRowsOn(article.body)" />
        </b-card>
        <br>

      </b-tab>

      <b-tab title="References" lazy>
        <br>
        <article-detail-references
          :article="article" 
          :article-summary="articleSummary"
          :literatum-schematron-errors="literatumSchematronErrors"
          :generic-schematron-errors="genericSchematronErrors"
          @UpdateAuditRequested="updateAudit"
        /> 
      </b-tab>

      <b-tab title="Miscl Back">
        <br>
        <b-card title="Sections"> 
          {{ article['miscl-back'].sections }}
        </b-card>
        <br>
        <b-card title="Footnotes"> 
          {{ article['miscl-back'].footnotes }}
        </b-card>
        <br>
        <b-card title="Ack"> 
          {{ article['miscl-back'].ack }}
        </b-card>
        <br>
      </b-tab>
     
    </b-tabs>
  </section>
</template>
 
<script>

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

//import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'

import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BCard, BTabs, BTab, BFormTextarea, BRow, BCol,
} from 'bootstrap-vue'

import ArticleDetailXmlSummary from './ArticleDetailXmlSummary.vue'
import ArticleDetailXmlTags from './ArticleDetailXmlTags.vue'
import ArticleDetailContributors from './ArticleDetailContributors.vue'
import ArticleDetailReferences from './ArticleDetailReferences.vue'

export default {

  components: {
    BCard,
    BTabs,  
    BTab, 
    BFormTextarea,
    BCardActions,
    BRow,
    BCol,
    //quillEditor,
    ArticleDetailXmlSummary,
    ArticleDetailXmlTags,
    ArticleDetailContributors,
    ArticleDetailReferences,
  },  
  directives: {
    Ripple,
  },
 
  props: {
    article: {
      type: Object,
      default: null
    },
    articleSummary: {
      type: Object,
      default: null
    },
    literatumSchematronErrors: {
      type: Array,
      default: null
    },
    genericSchematronErrors: {
      type: Array,
      default: null
    },
  },
  data () {
    return {
      editorOptions: {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            //['bold', 'italic', 'underline', 'strike'],
            ['bold', 'italic', 'underline'],
            //['blockquote', 'code-block'],
            //[{ 'header': 1 }, { 'header': 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            //[{ 'script': 'sub' }, { 'script': 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            //[{ 'direction': 'rtl' }],
            //[{ 'size': ['small', false, 'large', 'huge'] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }]
            //['clean']
            //['link', 'image', 'video']
          ]
        }
      },
    }
  },
  watch: {
  }, 
  mounted () { 
    //console.log('aaaaaaaa mounted', window.MathJax)
    //quillEditor.register(window.Mathjax)
    window.MathJax.typeset()
  },
  updated () { 
    //console.log('UPDATED lifecycle hook after mounted to ensure MathJax rendering')
    window.MathJax.typeset()
  },
  methods: { 
    textAreaRowsOn (content) {
      if (content.length > 5000) {
        return 50
      } 
      if (content.length > 1000) {
        return 30
      } 
      if (content.length > 800) {
        return  14
      } 
      if (content.length > 500) {
        return 12
      } 
      return  8
    },
    refTitle (ref) {
      let result = ''
      if (ref.unstructured) {
        result = ref.unstructured
      } else {
        if (ref.author) result = result.concat(ref.author, 'et al. ')
        if (ref['article-title']) result = result.concat(ref['article-title'], ' ')
        if (ref['journal-title']) result = result.concat(ref['journal-title'], ' ')
        if (ref.volume) result = result.concat(ref.volume, ' ')
        if (ref['first-page']) result = result.concat(ref['first-page'], ' ')
        if (ref.year) result = result.concat(' (', ref.year, ') ')
      }
      if (result.length === 0) result = ref.DOI
      //console.log('rrrrrrrrrrrrrrrrrrrr', ref, result)
      return result
    },
    doiUrl (doi) {
      //return 'https://dx.doi.org/'.concat(doi)  
      return '/publication/article-detail/'.concat(encodeURIComponent(doi))
      
    },
    affiliationNums (author) {
      let result = ''
      for (let i = 0; i < author.affiliation.length; i++) {
        const affName = author.affiliation[i].name
        if (result !== '') { 
          result = result.concat(',')
        }
        result = result.concat(this.affNameNumDict[affName])
      }
      return result
    }, 
    updateAudit (name) {
      this.$emit('UpdateAuditRequested', name)
    },
  }
}
</script>

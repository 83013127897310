<template>
  <section v-if="mounted"> 
    <b-card title="References">
      <span v-if="references">
        <span v-for="(item, index) in references" :key="index">
          <b-card bg-variant="light">
            <b-row>
              <b-col cols="2"><b>Label</b></b-col>
              <b-col cols="1">{{ item.label }}</b-col>
              <b-col cols="1"><b>ID</b></b-col>
              <b-col cols="1">{{ item.id }}</b-col>
              <b-col cols="2"><b>Fpage</b></b-col>
              <b-col v-if="item.fpage" cols="2">{{ item.fpage[0].value }}</b-col>
              <b-col cols="2"><b>Lpage</b></b-col>
              <b-col v-if="item.lpage" cols="1">{{ item.lpage[0].value }}</b-col>
            </b-row>
            <b-row>
              <b-col cols="3"><b>Source:</b> </b-col>
              <b-col cols="9"><span v-if="item.souce">{{ item.source[0].value }}</span></b-col>
            </b-row>
            <b-row v-if="item['article-title']">
              <b-col  cols="3"><b>Article Title:</b></b-col>
              <b-col  cols="9">{{ item['article-title'][0].value }}</b-col>
            </b-row>
            <b-row>
              <b-col cols="2"><b>Vol</b></b-col>
              
              <b-col v-if="item.volume" cols="1">{{ item.volume[0].value }}</b-col>
              <b-col cols="2"><b>Year</b></b-col>
              <b-col v-if="item.year" cols="1">{{ item.year[0].value }}</b-col>
            </b-row>
            <span v-if="item.people && item.people.children && item.people.children[0]['given-names']">
              <b-row v-for="(person, index1) in item.people.children" :key="index1">
                <b-col cols="3">{{ person['given-names'] }}</b-col>
                <b-col cols="3">{{ person.surname }}</b-col>
              </b-row> 
            </span>
            <span v-if="item.collab && item.collab[0]">
              <b-row>
                <b-col cols="12">{{ item.collab[0].value }}</b-col>
              </b-row>
            </span>
            <span v-if="item['publisher-name']">
              <b-row>
                <b-col cols="3">Journal</b-col>
                <b-col cols="9">{{ item['publisher-name'][0].value }}</b-col>
              </b-row>
              <b-row v-if="item['publisher-loc']">
                <b-col cols="3">Location</b-col>
                <b-col cols="9">{{ item['publisher-loc'][0].value }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="3">Year</b-col>
                <b-col cols="9">{{ item['year'][0].value }}</b-col>
              </b-row>
            </span>
          </b-card>
        </span>
      </span>
    </b-card>
  </section>
</template>
 
<script>

import detailUtil from '@/views/pubmill/detailUtil'

import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'

export default {

  components: {
    BRow,
    BCol,
    BCard,
  }, 
 
  props: {
    article: {
      type: Object,
      default: null
    }, 
    articleSummary: {
      type: Object,
      default: null 
    }, 
    literatumSchematronErrors: {
      type: Array,
      default: null
    },
    genericSchematronErrors: {
      type: Array,
      default: null
    },   
  },
  data () {
    return {
      references: null,
      mounted: null
    }
  },
  computed: {
  
  },
  mounted () { 
    //console.log('aaaaaaaaaaaaaaaaaa', in article-detail-references)

    this.references = detailUtil.referencesOn(this.article)
    //console.log('aaaaaaaaaaaaaaaaaa', this.references)
    this.mounted = true 
  },
  methods: {
  }
}
</script>

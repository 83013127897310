<template>
  <section> 
    <div v-if="keywordsFromArticle">
      <b-card>
        <h4> Keyword Recommendations</h4>
        <span v-if="keywordsFromArticle.trainingAnomaly">{{ keywordsFromArticle.trainingAnomaly }}</span>
        <span v-else v-for="(item, index) in keywordsFromArticle" :key="index"  >
          {{ item.keyword }}<span v-if="index < keywordsFromArticle.length -1">, </span>
        </span>
      </b-card>
    </div>

    <div v-if="subjectAreas">
      <b-card>
        <h4>Areas of Science</h4>
        <span v-for="(item, index) in subjectAreas" :key="index" >
          {{ item.subject }}<span v-if="index < subjectAreas.length -1">, </span>
        </span>
      </b-card>
    </div>

    <div v-if="articleShortSummary">
      <b-card>
        <h4> Short Summary</h4>
        <p>{{ articleShortSummary }}</p>
      </b-card>
    </div>

    <div v-if="articleMainFndings">
      <b-card>
        <h4> Main Findings</h4>
        <p>{{ articleMainFndings }}</p>
      </b-card>
    </div>

    <div v-if="imageForArticle">
      <b-card>
        <h4> Image Ideas</h4>
        <span v-if="imageForArticle.trainingAnomaly">{{ imageForArticle.trainingAnomaly }}</span>
        <b-row v-else>
          <span  v-for="(item, index) in imageForArticle" :key="index" >
            <b-col cols="3">
              <img :src="item"/>
            </b-col>
            <b-col cols="1" ></b-col>
          </span>
        </b-row>
      </b-card>
    </div>

    <div v-if="articlePressRelease">
      <b-card>
        <h4> Press Release </h4>
        <h5>{{ articlePressRelease.title1 }}</h5>
        <b>{{ articlePressRelease.title2 }}</b>
        <p> {{ articlePressRelease.message }} </p>
      </b-card>
    </div>

  </section>
</template>
 
<script>
import publicationApi from '@/dl_pubmill/apis/publication'

import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'

export default {
 
  components: {
    BCard, BRow, BCol,
  },

  props: {
    article: {
      type: Object,
      default: null
    },    
  },
  data () {
    return {
      keywordsFromArticle: null,
      imageForArticle: null,
      subjectAreas: null,
      articleShortSummary: null,
      articleMainFndings: null,
      articlePressRelease: null,
    }
  },
  computed: {

  },

  mounted () {
    //console.log('ddff1111', this.$route)
    let prodMode = 'published' 
    if (this.$route.path.includes('production-admin'))  prodMode = 'production'
    this.keywordsFromArticleRequest(this.$route.params.issn, prodMode)
    this.imageForArticleRequest(this.$route.params.issn, prodMode)
    this.subjectAreasRequest(this.$route.params.issn,  prodMode)
    this.articleShortSummaryRequest(this.$route.params.issn, prodMode)
    this.articleMainFndingsRequest(this.$route.params.issn, prodMode)
    this.articlePressReleaseRequest(this.$route.params.issn,  prodMode)
  },
  methods: {
    queryParamsOn (issn, fileStatus) {
      return {
        issn,
        volume: this.article.volume,
        issue: this.article.issue,
        file_status: fileStatus,
        file_name: this.article.file_name
      }
    },

    keywordsFromArticleRequest (issn, fileStatus) {
      const queryParams = this.queryParamsOn(issn, fileStatus)
      publicationApi.keywordsFromArticleRequest(queryParams, this, null, false)
    },
    backFromKeywordsFromArticleRequest (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        //console.log('backFromKeywordsFromArticleRequest', serverData)
        this.keywordsFromArticle = serverData
      } 
    },
    imageForArticleRequest (issn, fileStatus) {
      const queryParams = this.queryParamsOn(issn, fileStatus)
      publicationApi.imageForArticleRequest(queryParams, this, null, false)
    },
    backFromImageForArticleRequest (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        //console.log('backFromKeywordsFromArticleRequest', serverData)
        this.imageForArticle = serverData
      } 
    },
    subjectAreasRequest (issn, fileStatus) {
      const queryParams = this.queryParamsOn(issn, fileStatus)
      publicationApi.subjectAreasRequest(queryParams, this, null, false)
    },
    backFromSubjectAreasRequest (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        //console.log('backFromKeywordsFromArticleRequest', serverData)
        this.subjectAreas = serverData
      } 
    },
    articleShortSummaryRequest (issn, fileStatus) {
      const queryParams = this.queryParamsOn(issn, fileStatus)
      publicationApi.articleShortSummaryRequest(queryParams, this, null, false)
    },
    backFromArticleShortSummaryRequest (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        //console.log('backFromKeywordsFromArticleRequest', serverData)
        this.articleShortSummary = serverData
      } 
    },
    articleMainFndingsRequest (issn, fileStatus) {
      const queryParams = this.queryParamsOn(issn, fileStatus)
      publicationApi.articleMainFndingsRequest(queryParams, this, null, false)
    },
    backFromArticleMainFndingsRequest (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        console.log('backFromKeywordsFromArticleRequest', serverData)
        this.articleMainFndings = serverData
      } 
    },
    articlePressReleaseRequest (issn, fileStatus) {
      const queryParams = this.queryParamsOn(issn, fileStatus)
      publicationApi.articlePressReleaseRequest(queryParams, this, null, true)
    },
    backFromArticlePressReleaseRequest(serverData) {
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        //console.log('backFromKeywordsFromArticleRequest', serverData)
        this.articlePressRelease = serverData[0]
      } 
    },
  }
}
</script>

<template>
  <section> 
    <b-card> 
      <b-row> 
        <b-col cols="12">
          <article-operations 
            :xml-file="article.file_name" :article="article"
            @RefreshDataRequested="refreshData"
          />
        </b-col>
      </b-row> 
      <br>
      <b-row> 
        <b-col cols="4"> 
          <b>XML </b>
          <file-operations :format="article.format" :article="article" folder="xml" :file="article.file_name"/>
        </b-col>
        <b-col v-if="article.pdf_name" cols="4"> 
          <strong>PDF </strong>
          <file-operations :format="article.format" :article="article" folder="pdf" :file="article.pdf_name"/>
        </b-col>
        <b-col v-else cols="4"> <strong>PDF </strong><strong class="text-danger"> MISSING </strong> </b-col>
        <b-col cols="3"/>
      </b-row>
      <br>
      <b-row> 
        <b-col v-if="article.img_names && article.img_names.length > 0" cols="4"> 
          <b-row> <b-col><strong>Raw Images</strong></b-col></b-row>
          <b-row v-for="(image_name, index) in article.img_names" :key="index"> 
            <b-col>
              <file-operations :format="article.format" :article="article" folder="img" :file="image_name"/>
            </b-col>
          </b-row>
        </b-col>
        <b-col v-if="article.text_img_names && article.text_img_names.length > 0" cols="4"> 
          <b-row> <b-col><strong>Icons</strong></b-col></b-row>
          <b-row v-for="(image_name, index) in article.text_img_names" :key="index"> 
            <b-col>
              <file-operations :format="article.format" :article="article" folder="text_images" :file="image_name"/>
            </b-col>
          </b-row>
        </b-col>
        <b-col v-if="article.small_images_names && article.small_images_names.length > 0" cols="4"> 
          <b-row> <b-col><strong>Small Images</strong></b-col></b-row>
          <b-row v-for="(image_name, index) in article.small_images_names" :key="index"> 
            <b-col>
              <file-operations :format="article.format" :article="article" folder="small_images" :file="image_name"/>
            </b-col>
          </b-row> 
        </b-col>
        <b-col v-if="article.medium_images_names && article.medium_images_names.length > 0" cols="4"> 
          <b-row> <b-col><strong>Medium Images</strong></b-col></b-row>
          <b-row v-for="(image_name, index) in article.medium_images_names" :key="index"> 
            <b-col>
              <file-operations :format="article.format" :article="article" folder="medium_images" :file="image_name"/>
            </b-col>
          </b-row>
        </b-col>
        <b-col  v-if="article.large_images_names && article.large_images_names.length > 0"   cols="4"> 
          <b-row> <b-col><strong>Large Images</strong></b-col></b-row>
          <b-row v-for="(image_name, index) in article.large_images_names" :key="index"> 
            <b-col>
              <file-operations :format="article.format" :article="article" folder="large_images" :file="image_name"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <br>
      <span v-if=" article.suppl_manifest">
        <b-row>
          <b-col cols="6"> 
            <strong>Suppl Manifest </strong>
            <file-operations :format="article.format" :article="article" folder="suppl_manifest" :file="article.suppl_manifest"/>
          </b-col>
        </b-row>
        <br>
      </span>
      <b-row> 
        <b-col cols="6"> 
          <b-row> <b-col><strong>Supplemental</strong></b-col></b-row>
          <b-row v-for="(supp_name, index) in article.supplementary_names" :key="index"> 
            <b-col>
              <file-operations :format="article.format" :article="article" folder="supp" :file="supp_name"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>
 
<script>

import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'

import FileOperations from './FileOperations.vue'
import ArticleOperations from './ArticleOperations.vue'

export default {
 
  components: {
    FileOperations,
    ArticleOperations,
    BCard, 
    BRow, 
    BCol,
  },

  props: {
    article: {
      type: Object,
      default: null
    },   
  },
  data () {
    return {
    }
  },
  computed: {

  },
  methods: {
    refreshData () {
      //console.log('eeeeeeeeeeeq')
      this.$emit('RefreshDataRequested')
    },
  }
}
</script>

<template>
  <section v-if="finishedLoadingTaxonomy"> 
    <br>
    <h4>Article Catogories</h4>
    <div v-if="articleCategoryErrors">
      <b class="text-danger"> Errors</b>
      <div v-for="(item, index) in articleCategoryErrors" :key="index"> 
        <p class="text-danger">{{ item }}</p>
      </div>
    </div>
    <div class="demo-inline-spacing">
      <b-form-radio
        v-model="categoryViewMode"
        name="category-view-mode-radios"
        value="values"
      >
        Values
      </b-form-radio>
      <b-form-radio
        v-model="categoryViewMode"
        name="category-view-mode-radios"
        value="xml"
      >
        XML
      </b-form-radio>
      <b-button 
        v-if="(inProduction) && topicData != null"
        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
        variant="primary" 
        size="sm"
        @click.stop="promptToSelectNewTaxonomy()"
      >
        <feather-icon
          icon="EditIcon"
          class="mr-50"
        /> 
        Edit
      </b-button>
    </div>
    <br>
    <div v-if="categoryViewMode == 'values'" >
      <b-card>
        <div v-if="articleCategoryErrors">
          Unable to parse due to errors.
        </div>
        <div v-else-if="FORMAT == 'literatum'">
          <div  v-for="(cat, index) in articleSummary.literatumArticleCategories"  :key="index"> 
            <b-row>
              <b-col cols="2">{{ labelForTaxUri(cat) }}</b-col>
              <b-col cols="9">{{ tagUriLabels[cat.tagUri] }} ({{ cat.tagUri }})</b-col>
            </b-row>
          </div>
        </div>
        <div v-else-if="FORMAT == 'PMC'">
          <div  v-for="(cat, index) in articleSummary.pmcArticleCategories"  :key="index"> 
            <b-row>
              <b-col cols="12">
                <span v-if="cat.level6"> {{ cat.level6.replaceAll(':_:', ' / ') }} </span>
                <span v-else-if="cat.level5">{{ cat.level5.replaceAll(':_:', ' / ') }} </span>
                <span v-else-if="cat.level4">{{ cat.level4.replaceAll(':_:', ' / ') }} </span>
                <span v-else-if="cat.level3">{{ cat.level3.replaceAll(':_:', ' / ') }} </span>
                <span v-else-if="cat.level2">{{ cat.level2.replaceAll(':_:', ' / ') }} </span>
                <span v-else-if="cat.level1">{{ cat.level1  }} </span>         
              </b-col>
            </b-row>
            <br>
          </div>
        </div>
        <div v-else>
          Error: Format: {{ FORMAT }} not supported
        </div>
      </b-card>
      <br>
    </div>
    <div v-if="categoryViewMode == 'xml'" >
      <div  v-for="(snippet, index) in articleCategoryXMLTags"  :key="index"> 
        <b-card>
          <b-form-textarea v-model="snippet.child_tags" plaintext  :rows="textAreaRowsOn(snippet.child_tags)" />
        </b-card>
        <br>
      </div>
      <br>
    </div>
    <h4>Keywords</h4>
    <div>
      <div v-for="(parent, index) in keywordTags"  :key="index">
        <b-card-actions :title="parent.tag" title-color="primary" action-collapse>
          <div  v-for="(snippet, index2) in parent.child_tags"  :key="index2"> 
            <b-form-textarea v-if="parent.tag === snippet.tag" v-model="snippet.child_tags" plaintext  :rows="textAreaRowsOn(snippet.child_tags)" />
            <b-card-actions  v-else :title="snippet.tag" title-color="primary" action-collapse>
              <b-form-textarea v-model="snippet.child_tags" plaintext  :rows="textAreaRowsOn(snippet.child_tags)" />
            </b-card-actions>
            <br>
          </div>
        </b-card-actions>
        <br>
      </div>
    </div>
    <!-- MODALS HERE-->
    <!-- modal Taxonomy Selection-->
    <b-modal 
      v-if="topicData"
      id="taxonomy-modal-id" 
      scrollable
      title="Article Category Selection" 
      size="lg"
    >
      <b-tabs>
        <b-tab title="Type">
          <b-card> 
            <div  v-for="(item, index) in activityTypeData"  :key="index">
              <b-row>
                <b-col cols="12">
                  <b-form-checkbox
                    v-model="taxonomySelections"
                    :value="item.tagUri"
                    plain
                  >
                    {{ item.tagUri }} : {{ tagUriLabels[item.tagUri] }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-tab>
        <b-tab title="Topic">
          <b-card>
            <div  v-for="(item, index) in topicData"  :key="index">
              <b-row>
                <b-col v-if="item.parentTag" cols="1"/>
                <b-col cols="11">
                  <b-form-checkbox
                    v-model="taxonomySelections"
                    :value="item.tagUri"
                    plain
                  >
                    {{ item.tagUri }} : {{ tagUriLabels[item.tagUri] }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-tab>
        <b-tab title="Badge">
          <b-card>
            <div  v-for="(item, index) in badgeData"  :key="index">
              <b-row>
                <b-col cols="12">
                  <b-form-checkbox
                    v-model="taxonomySelections"
                    :value="item.tagUri"
                    plain
                  >
                    {{ item.tagUri }} : {{ tagUriLabels[item.tagUri] }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-tab>
        <b-tab title="Special Feature">
          <b-card>
            <div  v-for="(item, index) in specialFeatureData"  :key="index">
              <b-row>
                <b-col cols="12">
                  <b-form-checkbox
                    v-model="taxonomySelections"
                    :value="item.tagUri"
                    plain
                  >
                    {{ item.tagUri }} : {{ tagUriLabels[item.tagUri] }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-tab>
        <b-tab title="Colloquia">
          <b-card>
            <div  v-for="(item, index) in colloquiaData"  :key="index">
              <b-row>
                <b-col cols="12">
                  <b-form-checkbox
                    v-model="taxonomySelections"
                    :value="item.tagUri"
                    plain
                  >
                    {{ item.tagUri }} : {{ tagUriLabels[item.tagUri] }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-tab>
      </b-tabs>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-left"
            @click.stop="closeTaxonomyModal()"
          >
            Cancel
          </b-button>
          <b-button 
            v-if="taxonomySelections.length > 0"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="primary" 
            size="sm"
            class="float-right"
            @click.stop="promptFormTaxonomyConfirmation()"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            /> 
            Save
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- modal Taxonomy review and confirm-->
    <b-modal 
      v-if="finishedLoadingTaxonomy"
      id="taxonomy-confirmation-modal-id" 
      scrollable
      title="Article Category Review" 
      size="lg"
    >
      <b>Click Confirm to replace all categories with the items below.</b>
      <b-row v-for="(item, index) in buildNewTaxonomyFromSelections()"  :key="index">
        <b-col cols="3">
          {{ item.tagUri }}
        </b-col>
        <b-col cols="9">
          {{ item.tagLabel }}
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-left"
            @click.stop="closeTaxonomyConfirmationModal()"
          >
            Cancel
          </b-button>
          <b-button 
            v-if="taxonomySelections.length > 0"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="primary" 
            size="sm"
            class="float-right"
            @click.stop="replaceCatogoriesWithTaxonomySelections()"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            /> 
            Confirm 
          </b-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>
 
<script>
import publicationApi from '@/dl_pubmill/apis/publication'
import Ripple from 'vue-ripple-directive'

import { $themeConfig } from '@themeConfig'

import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BRow, BCol, BCard, BTabs, BTab, BFormTextarea, BButton, BFormCheckbox, BFormRadio
} from 'bootstrap-vue'

export default {

  components: {
    BRow,
    BCol,
    BCard,
    BTabs, 
    BTab, 
    BFormTextarea,
    BCardActions,
    BButton,
    BFormCheckbox,
    BFormRadio,
  }, 
  directives: {
    Ripple,
  },
 
  props: {
    article: {
      type: Object,
      default: null
    }, 
    articleSummary: {
      type: Object,
      default: null
    }, 
    literatumSchematronErrors: {
      type: Array,
      default: null
    },
    genericSchematronErrors: {
      type: Array,
      default: null
    },   
  },
  data () {
    return {
      FORMAT: null,
      tagUriLabels: null,
      finishedLoadingTaxonomy: null,
      activityTypeData: null,
      topicData: null,
      badgeData: null,
      specialFeatureData: null,
      colloquiaData: null,
      taxonomySelections: [],
      categoryViewMode: 'values',
    }
  },
  computed: {
    inProduction () {
      return this.$route.path.includes('production-admin')
    },
    articleCategoryXMLTags () {
      let out = []
      for (let i = 0; i < this.article.tags.length; i++) {
        //console.log('ss', this.article.tags[i].tag)
        if (this.article.tags[i].tag.includes('article-categories')) {
          out = this.article.tags[i].child_tags
          break
        }  
      }
      //console.log('ss out', out)
      return out
    },
    keywordTags () {
      const out = []
      for (let i = 0; i < this.article.tags.length; i++) {
        if (this.article.tags[i].tag.includes('kwd-group')) {
          out.push(this.article.tags[i])
        }
      }
      return out
    },     
    articleCategoryErrors () {
      let errors = null
      if (this.genericSchematronErrors != null) {
        for (let i = 0; i < this.genericSchematronErrors.length; i++) {
          if (this.genericSchematronErrors[i].location.includes('article-categories')) {
            if (errors == null) {
              errors = []
            }
            errors.push(this.genericSchematronErrors[i].message)
          }
        }
      }
      if (this.literatumSchematronErrors != null) {
        for (let i = 0; i < this.literatumSchematronErrors.length; i++) {
          if (this.literatumSchematronErrors[i].location.includes('article-categories')) {
            if (errors == null) {
              errors = []
            }
            errors.push(this.literatumSchematronErrors[i].message)
          }
        }
      }
      return errors
    }, 
  },
  mounted () { 
    this.FORMAT =  $themeConfig.app.envVars.FORMAT
    if (this.FORMAT === 'literatum') {
      this.getTaxonomy() 
    } else {
      this.finishedLoadingTaxonomy = 'y'
    }   
  },
  methods: {
    labelForTaxUri (cat) {
      if (cat.taxUri  === 'type') {
        return 'Type'
      }
      if (cat.taxUri  === 'topic') {
        return 'Topic'
      }
      if (cat.taxUri  === 'badge') {
        return 'Badge'
      }
      if (cat.taxUri  === 'sp-feat') {
        return 'Special Feature'
      }
      if (cat.taxUri  === 'colloquia') {
        return 'Colloquia'
      }
      return 'Unknown'
    },
    textAreaRowsOn (content) {
      if (content.length > 5000) {
        return 50
      } 
      if (content.length > 1000) {
        return 30
      } 
      if (content.length > 800) {
        return  14
      } 
      if (content.length > 500) {
        return 12
      } 
      return  8
    },
    promptToSelectNewTaxonomy () {
      this.taxonomySelections = []
      for (let i = 0; i < this.articleSummary.articleCategories.length; i++) {
        this.taxonomySelections.push(this.articleSummary.articleCategories[i].tagUri)
      }
      this.$bvModal.show('taxonomy-modal-id')
    },
    closeTaxonomyModal () {
      this.$bvModal.hide('taxonomy-modal-id')
    },
    promptFormTaxonomyConfirmation () {      
      this.$bvModal.show('taxonomy-confirmation-modal-id')
    },
    closeTaxonomyConfirmationModal () {
      this.$bvModal.hide('taxonomy-confirmation-modal-id')
    },
    buildTaxUriDataOn (taxIn) {
      const parentChildRelationships = {}
      for (let i = 0; i < taxIn.tagUris.length; i++) {
        const tagUri =  taxIn.tagUris[i]
        if (tagUri.parentTag) {
          if (!parentChildRelationships[tagUri.parentTag]) {
            parentChildRelationships[tagUri.parentTag] = []
          }
          parentChildRelationships[tagUri.parentTag].push(tagUri)
        }
      }
      //console.log('parentChildRelationships', parentChildRelationships)
      const taxOut = []
      const keys = Object.keys(parentChildRelationships)
      //console.log('keys', keys)
      keys.sort()
      for (let i = 0; i < keys.length; i++) {
        let toRec = { taxUri: taxIn.taxUri, tagUri: keys[i] }
        taxOut.push(toRec)
        //console.log('parent', toRec.tagUri)
        const tagUris = parentChildRelationships[keys[i]]
        for (let j = 0; j < tagUris.length; j++) {
          toRec = { taxUri: taxIn.taxUri, tagUri: tagUris[j].tagUri, parentTag: tagUris[j].parentTag }
          //console.log('child', toRec.tagUri,  toRec.parentTag)
          taxOut.push(toRec)
        } 
      }
      for (let i = 0; i < taxIn.tagUris.length; i++) {
        const tagUri = taxIn.tagUris[i]
        //console.log('dddddddddddd', tagUri)
        //skip root tags because they were added above
        if (!tagUri.parentTag && parentChildRelationships[tagUri.tagUri]) {
          //console.log('skipping ', tagUri)
          continue
        }
        //skip tags with parent because they were added above
        if (tagUri.parentTag) {
          //console.log('skipping ', tagUri)
          continue
        }
        const toRec = { taxUri: taxIn.taxUri, tagUri: tagUri.tagUri, parentTag: tagUri.parentTag }
        taxOut.push(toRec)
      }
      //console.log('ttttxxx out', taxOut)
      return taxOut 
    },
    getTaxonomy () {
      this.activityTypeData = null
      this.topicData = null
      this.badgeData = null
      this.specialFeatureData = null
      this.colloquiaData = null
      this.finishedLoadingTaxonomy = null
      publicationApi.getTaxonomy(this.$route.params.issn, 'n', this, null)
    },
    backFromGetTaxonomy (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
        return
      } 
      //console.log('backFromTaxonomy', serverData)
      if (!serverData.taxUris) {
        this.finishedLoadingTaxonomy = 'y'
        return
      }
      this.tagUriLabels = serverData.tagUriLabels

      this.activityTypeData = this.buildTaxUriDataOn(serverData.taxUris['Article Types'])
      this.topicData = this.buildTaxUriDataOn(serverData.taxUris.Topics)
      this.badgeData = this.buildTaxUriDataOn(serverData.taxUris.Badges)
      this.specialFeatureData = this.buildTaxUriDataOn(serverData.taxUris['Special Feature'])
      this.colloquiaData = this.buildTaxUriDataOn(serverData.taxUris.Colloquia)

      const tempDict = {} 
      let data = this.activityTypeData
      //console.log('aaaaa', data)
      for (let i = 0; i < data.length; i++) {
        const key = data[i].tagUri
        tempDict[key] = data[i]
      }
      data = this.topicData
      for (let i = 0; i < data.length; i++) {
        const key = data[i].tagUri
        //console.log('00000', data[i].tagUri)
        tempDict[key] = data[i]
        //console.log('111111', key, tempDict[key])
      }
      data = this.badgeData
      for (let i = 0; i < data.length; i++) {
        const key = data[i].tagUri
        tempDict[key] = data[i]
      }
      data = this.specialFeatureData
      for (let i = 0; i < data.length; i++) {
        const key = data[i].tagUri
        tempDict[key] = data[i]
      }
      data = this.colloquiaData
      for (let i = 0; i < data.length; i++) {
        const key = data[i].tagUri
        tempDict[key] = data[i]
      }
      this.tagUriDict = tempDict
  
      this.finishedLoadingTaxonomy = 'y'

    }, 
    buildNewTaxonomyFromSelections () {

      //console.log('ttttttttttttt', this.tagUriDict, this.tagUriDict)
      const parentsOfChildrenSelected = new Set()
      for (let i = 0; i <  this.taxonomySelections.length; i++) {
        const rec = this.tagUriDict[this.taxonomySelections[i]]
        if (rec.parentTag) {
          parentsOfChildrenSelected.add(rec.parentTag)
        }
      }
      //console.log('parentsOfChildrenSelected', parentsOfChildrenSelected)
      const newTaxonomy = []
      for (let i = 0; i <  this.taxonomySelections.length; i++) {
        const rec = this.tagUriDict[this.taxonomySelections[i]]
        //don't add parent selections when children are selected
        if (!rec.parentTag && parentsOfChildrenSelected.has(rec.tagUri)) {
          //console.log('skipping', rec)
          continue
        }
        newTaxonomy.push({ tagCode: rec.tagUri, tagLabel: this.tagUriLabels[rec.tagUri], tagUri: rec.taxUri })
      }
             
      //console.log('newTaxonomy', newTaxonomy)
      return newTaxonomy

    },
    replaceCatogoriesWithTaxonomySelections () {
      const newTaxonomy = this.buildNewTaxonomyFromSelections()
      publicationApi.replaceCatogoriesWithTaxonomySelections(this.$route.params.issn, this.$route.params.vol, this.$route.params.issue, this.articleSummary.file_name, newTaxonomy, this, null)
  
    },
    backFromReplaceCatogoriesWithTaxonomySelections(serverData) {
      console.log('backFromReplaceCatogoriesWithTaxonomySelections', serverData)
      if (serverData.error) {
        console.log('serverData.error', serverData.error)
        this.$bvModal
          .msgBoxOk(serverData.error, {
            title: 'Sorry.  There was a problem with this operation',
            size: 'sm',
            okVariant: 'danger',
            modalClass: 'modal-danger',
            okTitle: 'Ok',
          })
      } else { 
        this.closeTaxonomyModal()
        this.closeTaxonomyConfirmationModal()
        this.$emit('UpdateAuditRequested', 'Article Category Changes')
      }
    },

  }
}
</script>

<template>
  <section> 
    <b-row>
      <b-col cols="3">
        <b-card v-if="articleVersions" :key="renderKey">
          <b-button 
            v-if="selected.length == 2"
            variant="flat-primary"
            @click.stop="runComparison()"
          >
            Run Another Comparison
          </b-button>
          <br>
          <div v-for="(item, index) in articleVersions" :key="index" >
            <b-form-checkbox :checked="selectedVersions[index]"  @input="updateSelectedVersions(index, $event)">
              <b> {{ item.time }} </b>
            </b-form-checkbox>
          </div>
        </b-card>
      </b-col>
      <b-col cols="9">
        <b-card v-if="comparisons">
          <div v-for="(comparison, index) in comparisons" :key="index" >
            {{  comparison.action.name  }} :  {{ comparison.version_1_path }}
            <br>
            <b>New</b>
            {{ comparison.version_1_tag }} ...
            <span v-if="comparison.action.value">{{ truncate(comparison.action.value, 500) }}</span>
            <span v-if="comparison.action.new_value"> {{ truncate(comparison.action.new_value, 500) }} </span>
            <div v-if="comparison.version_2_tag">
              <br>
              <b-card bg-variant="light">
                <b>Old</b>
                {{ comparison.version_2_tag }} ...
                <span v-if="comparison.action.old_value"> <br>{{ truncate(comparison.action.old_value, 500) }} </span>
 
              </b-card>
            </div>
            <br>
          </div>
          
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
 
<script>
import publicationApi from '@/dl_pubmill/apis/publication'

import {
  BCard, BFormCheckbox, BButton, BRow, BCol
} from 'bootstrap-vue'

export default {
 
  components: {
    BCard, BFormCheckbox, BButton, BRow, BCol
  },

  props: {
    article: {
      type: Object,
      default: null
    },    
  },
  data () {
    return {
      articleVersions: null,
      selectedVersions: [],
      //needed to refresh checknoxes
      renderKey: 0,
      comparisons: null
    }
  },
  computed: {
    selected () { 
      const items = []
      for (let i = 0; i < this.selectedVersions.length; i++) {
        if (this.selectedVersions[i] === true) {
          items.push(this.articleVersions[i])
        }
      }
      return items
    },
  },
  mounted () {
    //console.log('ddff1111', this.$route)

    let prodMode = 'published' 
    if (this.$route.path.includes('production-admin'))  prodMode = 'production'
    this.getArticleXMLVersions(this.$route.params.issn, prodMode)
    
  },
  methods: {
    truncate(str, maxLength) {
      if (str.length > maxLength) {
        // eslint-disable-next-line prefer-template
        return str.slice(0, maxLength) + '...'
      }
      return str
    },
    updateSelectedVersions(index, isChecked) {
      console.log('iiiiii', index, isChecked)
      this.$set(this.selectedVersions, index, isChecked)

      const trueValues = this.selectedVersions.filter(value => value === true).length

      if (trueValues > 2) {
        let trueFound = 0
        for (let i = 0; i < this.selectedVersions.length; i++) {
          if (this.selectedVersions[i] === true) {
            trueFound++
            if (trueFound > 2) {
              this.$set(this.selectedVersions, i, false)
            }
          }
        }
        //needed to refresh checknoxes
        this.renderKey++
      }
    },
    getArticleXMLVersions (issn, fileStatus) {
      const queryParams = {
        issn,
        volume: this.article.volume,
        issue: this.article.issue,
        file_status: fileStatus,
        file_name: this.article.file_name
      }
      console.log('xxxxxxxxx', queryParams)
      publicationApi.getArticleXMLVersions(queryParams, this, null)
    },

    backFromGetArticleXMLVersions (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        console.log('zzzbackFromGetArticleXMLVersions', serverData)
        this.articleVersions = serverData
        let prodMode = 'published' 
        if (this.$route.path.includes('production-admin'))  prodMode = 'production'
        const versionId1 = this.articleVersions[0].id
        let versionId2 = null
  
        if (this.articleVersions.length === 1) {
          //workaround for forcing call on only one version to close spinner  
          versionId2 = versionId1
        } else {
          versionId2 = this.articleVersions[1].id
          this.selectedVersions = [true, true]
        }
        this.compareVersions(this.$route.params.issn, prodMode,  versionId1, versionId2)
        
      } 
    },
    runComparison () {
      let prodMode = 'published' 
      if (this.$route.path.includes('production-admin'))  prodMode = 'production'
      const items = this.selected
      const versionId1 = items[0].id
      const versionId2 = items[1].id
      this.compareVersions(this.$route.params.issn, prodMode,  versionId1, versionId2)
      
    },
    compareVersions (issn, fileStatus, versionId1,  versionId2) {
      const queryParams = {
        issn,
        volume: this.article.volume,
        issue: this.article.issue,
        file_status: fileStatus,
        file_name: this.article.file_name,
        version_1_id: versionId1,
        version_2_id: versionId2,
      }
      console.log('compareVersions', queryParams)
      publicationApi.compareVersions(queryParams, this, null)
    },

    backFromCompareVersions (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        console.log('zzzbackFromCompareVersions', serverData)
        this.comparisons = serverData
        
      } 
    },
  }
}
</script>

<template>
  <div>
    <div v-if="error404">
      <br><br>
      <h2> {{ error404 }} </h2>
    </div>
    <div v-if="article && fileName">
      <b-row>
        <b-col cols="12">
          <span v-if="inProduction"> 
            <h5 class="content-header-title float-left pr-1 mb-0" style="margin-right:1rem;"> 
              Production
            </h5>
            <h5 class="content-header-title float-left pr-1 mb-0" style="margin-right:1rem;"> 
              {{ articleSummary.article_type }}
            </h5>
            <h5> 
              <router-link :to="archiveIssueRouterName"> Volume: {{ volume }} - Issue: {{ issue }}</router-link>
              / {{ articleSummary.doi }}
            </h5>
          </span>
          <span v-else>
            <h5 class="content-header-title float-left pr-1 mb-0" style="margin-right:1rem;"> 
              Published
            </h5>
            <h5 class="content-header-title float-left pr-1 mb-0" style="margin-right:1rem;"> 
              {{ articleSummary.article_type }}
            </h5>
            <h5> 
              <router-link :to="archiveVolRouterName">Volume: {{ volume }}</router-link>
              / 
              <router-link :to="archiveIssueRouterName">Issue: {{ issue }}</router-link>
              / {{ articleSummary.doi }}
            </h5>
          </span>
        </b-col>      
      </b-row>
      <b-card bg-variant="light"> 
        <b-card>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <h5 v-if="articleSummary.title && articleSummary.title.length > 0" v-html="$sanitize(articleSummary.title)"></h5>
          <h5 v-else class="text-danger"> Title Missing</h5>
          <br>
          <b-row> 
            <b-col  cols="2">
              <a :href="webViewUrl" target="_blank">Web View</a>
            </b-col>
            <b-col  cols="2">
              <a :href="'https://doi.org/'.concat(articleSummary.doi)" target="_blank">Host View</a>
            </b-col>
            <b-col v-if="tablesDatasetsVideosMathML" cols="8">
              <b-row> 
                <b-col v-if="tablesDatasetsVideosMathML[0]" cols="3"> <span class="text-success">Has Tables</span></b-col>
                <b-col v-if="tablesDatasetsVideosMathML[1]" cols="3"> <span class="text-success">Has MathML</span></b-col>
                <b-col v-if="tablesDatasetsVideosMathML[2]" cols="3"> <span class="text-success">Datasets</span> ({{ tablesDatasetsVideosMathML[2] }})</b-col>
                <b-col v-if="tablesDatasetsVideosMathML[3]" cols="3"> <span class="text-success">Videos</span> ({{ tablesDatasetsVideosMathML[3] }})</b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="articleSummary.relatedArticles.length > 0"> 
            <b-col  cols="9">
              - related to: 
              <span v-for="(item, index) in articleSummary.relatedArticles" :key="index"> 
                <span v-if="index > 0"> , </span> 
                <a :href="doiUrlPrefix.concat(item.doi)" target="_blank">{{ item.raType }}</a>
              </span>
            </b-col>
          </b-row>
          <b-row v-if="inProduction">
            <b-col  cols="2" style="margin-top:.5rem;margin-left:-.7rem;">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-primary"
                class="btn-icon"
                @click="$bvModal.show('prompt-to-add-note')"
              >
                <feather-icon icon="FilePlusIcon" />
                Add Note
              </b-button>
              <b-modal
                id="prompt-to-add-note" 
                centered
                title="Add Note" 
                ok-title="Accept"
                cancel-title="Cancel"
                size="lg"
                @ok="addNote"
              >
                <notes-and-notify ref="notesAndNotify" />
              </b-modal>
            </b-col>
          </b-row>
          <b-row v-if="published">
            <b-col  cols="5" style="margin-top:.5rem;margin-left:-.7rem;">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-primary"
                class="btn-icon"
                @click="$bvModal.show('prompt-for-reopen-in-production')"
              >
                <feather-icon icon="FilePlusIcon" />
                Re-Open Article in Production
              </b-button>
              <b-modal
                id="prompt-for-reopen-in-production"
                centered
                title="Re-Open Article in Production" 
                ok-title="Accept"
                cancel-title="Cancel"
                size="lg"
                @ok="reopenInProduction"
              >
                <notes-and-notify ref="notesAndNotify" />
              </b-modal>
            </b-col>
          </b-row>
        </b-card> 
        <b-tabs v-model="tabIndex">
          <b-tab title="XML"> 
            <article-detail-xml 
              :article="article" 
              :article-summary="articleSummary"
              :literatum-schematron-errors="literatumSchematronErrors"
              :generic-schematron-errors="genericSchematronErrors"
              @UpdateAuditRequested="updateAudit"
            />
          </b-tab>
          <b-tab title="PDF" lazy> 
            <article-detail-pdf :article="articleSummary"/>
          </b-tab>
          <b-tab title="Files">  
            <article-detail-files 
              :article="articleSummary"
              @RefreshDataRequested="refreshData"
            />
          </b-tab>
          <b-tab title="Audit"> 
            <article-detail-audit 
              :article="articleSummary"
              :all-history="allHistory"
            />
          </b-tab>
          <b-tab title="Versions" lazy> 
            <article-detail-versions 
              :article="articleSummary"
            />
          </b-tab>
          <b-tab title="Labs" lazy> 
            <article-detail-labs 
              :article="articleSummary"
            />
          </b-tab>
          <b-tab v-if="totalValidationErrors > 0"> 
            <template #title>
              <span class="pill_gap">Errors</span>
              <b-badge pill variant="danger">{{ totalValidationErrors.toLocaleString() }}</b-badge>
            </template>
            <b-card>
              <b-row>
                <b-col cols="12"> {{ article.public_id }}</b-col>
              </b-row>
              <span v-if="xsdValidationError">
                <b-row>
                  <b-col cols="4"> <strong class="text-danger"> XSD Failure </strong></b-col>
                  <b-col cols="8"> {{ xsdValidationError }}</b-col>
                </b-row>
              </span>
              <span v-if="genericSchematronErrors.length > 0">
                <br>
                <b-row>
                  <b-col cols="4"> <strong class="text-danger">Generic XML Errors</strong></b-col>
                </b-row>
                <b-row v-for="(sch_error, index) in genericSchematronErrors"  :key="index">
                  <b-col cols="4"> {{ sch_error.location }}</b-col>
                  <b-col cols="8"> {{ sch_error.message }}</b-col>
                </b-row>
              </span>
              <span v-if="literatumSchematronErrors.length > 0">
                <br>
                <b-row>
                  <b-col cols="4"> <strong class="text-danger">Host Platform XML Errors</strong></b-col>
                </b-row>
                <b-row v-for="(sch_error, index) in literatumSchematronErrors"  :key="index">
                  <b-col cols="4"> {{ sch_error.location }}</b-col>
                  <b-col cols="8"> {{ sch_error.message  }}</b-col>
                </b-row>
              </span>
            </b-card> 
          </b-tab>
          <b-tab v-if="totalValidationWarnings > 0"> 
            <template #title>
              <span class="pill_gap">Warnings</span>
              <b-badge pill variant="warning">{{ totalValidationWarnings.toLocaleString() }}</b-badge>
            </template>
            <b-card>
              <span v-if="genericSchematronErrors.length > 0">
                <br>
                <b-row>
                  <b-col cols="4"> <strong class="text-danger">Generic XML Errors</strong></b-col>
                </b-row>
                <b-row v-for="(key, index) in Object.keys(articleSummary.warnings)"  :key="index">
                  <b-col cols="4"> {{ key }}</b-col>
                  <b-col cols="8"> {{ articleSummary.warnings[key] }}</b-col>
                </b-row>
              </span>
              <span v-if="literatumSchematronWarnings.length > 0">
                <br>
                <b-row>
                  <b-col cols="4"> <strong class="text-warnings">Host Platform XML Warnings</strong></b-col>
                </b-row>
                <b-row v-for="(sch_error, index) in literatumSchematronWarnings"  :key="index">
                  <b-col cols="4"> {{ sch_error.location }}</b-col>
                  <b-col cols="8"> {{ sch_error.message  }}</b-col>
                </b-row>
              </span>
            </b-card> 
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template> 
 
<script>

import publicationApi from '@/dl_pubmill/apis/publication'
import detailUtil from '@/views/pubmill/detailUtil'
 
import {
  BCard, BRow, BCol, BTabs, BTab, BBadge, BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import ArticleDetailPdf from '@/views/pubmill/reader/web/article/ArticleDetailPdf.vue'
import ArticleDetailXml from './ArticleDetailXml.vue'
import ArticleDetailFiles from './ArticleDetailFiles.vue'
import ArticleDetailAudit from './ArticleDetailAudit.vue'
import ArticleDetailVersions from './ArticleDetailVersions.vue'
import ArticleDetailLabs from './ArticleDetailLabs.vue'
import NotesAndNotify from '../issue/NotesAndNotify.vue'

export default {
  components: {
    ArticleDetailXml,
    ArticleDetailPdf,
    ArticleDetailFiles,
    ArticleDetailAudit,
    ArticleDetailVersions,
    ArticleDetailLabs,
    BCard,
    BRow,
    BCol,
    BTabs, 
    BTab,
    BBadge,
    BButton, 
    NotesAndNotify,
  },
  directives: {
    Ripple,
  },

  data () {
    return {
      volume: null,
      issue: null,
      fileName: null,
      tabIndex: 0,
      article: null,
      articleSummary: null,
      allHistory: null,
      xsdValidationError: null,
      genericSchematronErrors: null,
      literatumSchematronErrors: null,
      literatumSchematronWarnings: null,
      unsupportedTags: null,
      affNameNumDict: {},
      affiliationList: [],
      showWarnings: true,
      error404: null
    }
  },
  computed: {  

    tablesDatasetsVideosMathML () {
      return detailUtil.tablesDatasetsVideosMathML(this.articleSummary)
    },
    doiUrlPrefix () {
      const { host } = window.location
      const prot = host.includes('localhost') ? 'http://' : 'https://'
      return prot.concat(host, '/publication-admin/', this.$route.params.issn, '/doi/')
    },
    webViewUrl() {
      const nodes = this.articleSummary.doi.split('/')
      let doiSuff = this.articleSummary.doi.split(nodes[0].concat('/'))[1]
      if (doiSuff.includes('/')) doiSuff = doiSuff.replace('/', '.')
      const { host } = window.location
      const prot = host.includes('localhost') ? 'http://' : 'https://'
      let url = null
      if (this.inProduction) {
        url = prot.concat(host, '/production-web/article-detail/', this.$route.params.issn, '/', this.articleSummary.volume, '/', this.articleSummary.issue, '/', this.articleSummary.file_name.split('.xml')[0])
      } else {
        url = prot.concat(host, '/publication-web/', this.$route.params.issn, '/doi/', nodes[0].concat('/', doiSuff))
      }
      return url
    },
    totalValidationErrors () {
      return detailUtil.totalValidationErrors(this.xsdValidationError, this.genericSchematronErrors, this.literatumSchematronErrors)           
    },
    totalValidationWarnings () {
      return detailUtil.totalValidationWarnings(this.articleSummary, this.literatumSchematronWarnings)          
    },
    inProduction () {
      return this.$route.path.includes('production-admin')
    },
    published () {
      return (!this.inProduction)
    }, 
    archiveVolRouterName () {
      return {
        name: 'publication-archive-admin-'.concat(this.volume), 
        params: {
          issn: this.$route.params.issn,
          vol: this.volume,
        }
      }
    },
    archiveIssueRouterName () {
      const returnDict = {
        params: {
          issn: this.$route.params.issn,
          issue: this.issue,
        }
      }
      if (this.inProduction) {
        returnDict.name = 'production-issue-admin'
        returnDict.params.vol = this.volume
      } else {
        returnDict.name = 'publication-issue-admin'
        returnDict.params.vol = this.volume
      }
      
      return returnDict
    },
    
    articlePublishedOnline () {
      return detailUtil.articlePublishedOnline(this.article)
    },
    articlePublishedPrint () {
      return detailUtil.articlePublishedPrint(this.article)
    }
  },
  mounted () {
    this.volume = this.$route.params.vol
    this.issue = this.$route.params.issue
    this.fileName = this.$route.params.file_name
    this.refreshData()
  },
  methods: {
    addNote () {
      this.updateAudit(null)
      this.tabIndex = 3
    },
    reopenInProduction () {
      let additionalNotes = this.$refs.notesAndNotify.additionalNotes
      if (additionalNotes !== null) {
        console.log('additionalNotes', additionalNotes)
        const tmp = additionalNotes.trim().replace(/\s+/g, '')
        if (tmp === '' || tmp === '<p></p>') {
          additionalNotes = null
        } 
      }

      //console.log('additionalNotes final ', additionalNotes)
      //console.log('uuuu', this.$refs.notesAndNotify.usersToNotify)
      const notesAndNotification = {
        additionalNotes,
        usersToNotify: this.$refs.notesAndNotify.usersToNotify
      }

      const dois = [this.articleSummary.doi]
      const issueFileNames = []
      publicationApi.reopenInProduction(this.$route.params.issn, this.$route.params.vol, this.$route.params.issue, dois, issueFileNames, notesAndNotification, this, null)
    },
    backFromReopenInProduction (serverData) {
      if (serverData.error) {
        console.log('backFromFeopenInProduction with error', serverData.error)
        const message = 'A problem occured during the intitial setup of the tranmission.  Please contact your administrator.'
        this.showProblemWithReopenPreparation(message)
      } else {
        this.promptToRedirectToProduction()
      } 
    }, 

    promptToRedirectToProduction () {

      const message = 'Operation has commenced for your selections. You will now be redirected to the production tracking.  Note that it may take some time for all files to be copied over.'
      //this.$bvModal.msgBoxConfirm(message,..
      this.$bvModal
        .msgBoxOk(message, {
          title: 'Operation in Progress',
          size: 'sm',
          okVariant: 'primary',
          modalClass: 'modal-primary',
          okTitle: 'Ok',
        })
        .then(value => {
          if (value) {
            this.$router.push({ name: 'production-admin', params: { issn: this.$route.params.issn } })
          }
        })
    },

    showProblemWithReopenPreparation (message) {
      this.$bvModal
        .msgBoxOk(message, {
          title: 'Problem with Transmission',
          size: 'sm',
          okVariant: 'danger',
          modalClass: 'modal-danger',
          okTitle: 'Ok',
        })
    },
    updateAudit (changeActivityName) {

      const notesAndNotification = {
        usersToNotify: []
      }
      if (this.$refs.notesAndNotify) {
        let additionalNotes = this.$refs.notesAndNotify.additionalNotes
        if (additionalNotes !== null) {
          console.log('additionalNotes', additionalNotes)
          const tmp = additionalNotes.trim().replace(/\s+/g, '')
          if (tmp === '' || tmp === '<p></p>') {
            additionalNotes = null
          } 
          notesAndNotification.additionalNotes = additionalNotes
        }
        notesAndNotification.usersToNotify = this.$refs.notesAndNotify.usersToNotify
      }
      const dois = [this.articleSummary.doi]
  
      //console.log('staging ddd issueFileNames', issueFileNames, 'dois', dois)
      publicationApi.updateAudit(this.$route.params.issn, this.volume, this.issue, dois, [], changeActivityName, notesAndNotification, this, null)
      
    },
    
    backFromUpdateAudit (serverData) {
      if (serverData.error) {
        console.log('backFromUpdateAudit with error', serverData.error)
      }
      console.log('backFromUpdateAudit success')  
      this.refreshData()
    }, 
    
    resolveArticleXMLDetail () {
      console.log('ArticleDetailAdmin.vue ffffffxxxxxxxxx')
      if (this.$route.params.doi_prefix) {
        this.searchByDOI(this.$route.params.doi_prefix.concat('/', this.$route.params.doi_suffix))
        return   
      }

      const issn =  this.$route.params.issn
      let fileStatus = 'published'
      if (this.inProduction) {
        fileStatus = 'production'
      }
      this.getArticleXMLDetail(issn, fileStatus)
    },
    searchByDOI (doi) {
      publicationApi.searchByDOI(doi, this.$route.params.issn, this, null)
    },
    backFromSearchByDOI (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
        this.error404 = serverData.error
      } else if (serverData.hits.total.value > 0) {
        const result = serverData.hits.hits[0]
        this.volume = result.source.volume
        this.issue = result.source.issue 
        this.fileName = result.id
        this.getArticleXMLDetail(this.$route.params.issn, 'published')
      } else {
        this.error404 = '404: DOI not found'
      }
    },
    getArticleXMLDetail (issn, fileStatus) {
      const queryParams = {
        issn,
        volume: this.volume,
        issue: this.issue,
        file_status: fileStatus,
        file_name: this.fileName.concat('.xml')
      }
      //console.log('xxxxxxxxx', queryParams)
      publicationApi.getArticleXMLDetail(queryParams, this, null)
    },

    backFromGetArticleXMLDetail (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        console.log('zzzbackFromGetArticleXMLDetail', serverData)
        this.articleSummary = serverData.articleSummary
        
        const tmp = []
        if (serverData.audit && serverData.audit.changes) {
          for (let i = 0; i < serverData.audit.changes.length; i++) {
            const rec = serverData.audit.changes[i]
            rec.type = 'changes'
            tmp.push(rec)
          }
        }
        if (serverData.audit && serverData.audit.staging) {
          for (let i = 0; i < serverData.audit.staging.length; i++) {
            const rec = serverData.audit.staging[i]
            rec.type = 'staging'
            tmp.push(rec)
          }
        }
        if (serverData.audit && serverData.audit.notes) {
          for (let i = 0; i < serverData.audit.notes.length; i++) {
            const rec = serverData.audit.notes[i]
            rec.type = 'notes'
            tmp.push(rec)
          }
        }

        tmp.sort((a, b) => {
          if (a.timestamp > b.timestamp) {
            return -1
          }
          if (a.timestamp < b.timestamp) {
            return 1
          }
          return 0
        })
        //console.log('aaaaaaa', tmp)

        this.allHistory = tmp
    
        this.xsdValidationError = detailUtil.xsdValidationError(serverData)
        this.genericSchematronErrors = detailUtil.genericSchematronErrors(serverData)
        // console.log('zzbbddda', serverData.literatumSchematronValidationResult)
        this.literatumSchematronErrors = detailUtil.literatumSchematronErrors(serverData)
        this.literatumSchematronWarnings = detailUtil.literatumSchematronWarnings(serverData)

        /* 
        let cnt = 1
        for (const i in serverData.article_json.author) {
          const auth = serverData.article_json.author[i]
          for (const j in auth.affiliation) {
            const affName = auth.affiliation[j].name
            if (!this.affNameNumDict[affName]) {
              this.affNameNumDict[affName] = cnt.toString()
              this.affiliationList.push([cnt.toString(), affName])
              cnt += 1
            }
          }
        }
        */
        const currentPublicationName = this.$store.state.pubmillGlobalStore.currentPublicationObject.name
        if (!currentPublicationName) {
          console.log('currentPublicationName not found resetting from issn', this.$route.params.issn)
          this.$store.commit('pubmillGlobalStore/updateCurrentPublicationId', this.$route.params.issn)
        }

        this.article = serverData.article_json
        //this.article.body = this.article.body.split('Latex converted to MathML')[1].split('<inline-formula>')[1].split('</inline-formula>')[0]
        //this.article.body = this.article.body.split('Latex converted to MathML')[1]
        //this.article.body = this.article.body.split('Math Tests Original Latex')[1]
        //this.article.body = this.article.body.split('<body xmlns:mml="http://www.w3.org/1998/Math/MathML" xmlns:xlink="http://www.w3.org/1999/xlink">')[1]
        //console.log('ggg', this.article.body)
      } 
    },
    refreshData () {
      this.resolveArticleXMLDetail()
    }
  },
}
</script>

<style lang="scss">
.pill_gap {
    margin-right:5px;
}
</style>

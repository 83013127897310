<template>
  <section v-if="mounted"> 
    <b-card title="Contributors">
      <span v-if="contributors">
        <span v-for="(item, index) in contributors" :key="index">
          <b-card bg-variant="light">
            <b-row>
              <b-col cols="1"><b>Type</b></b-col>
              <b-col cols="2">{{ item.type }}</b-col>
              <b-col cols="1"><b>First</b></b-col>
              <b-col cols="2">{{ item['given-names'] }}</b-col>
              <b-col cols="1"><b>Last</b></b-col>
              <b-col cols="2">{{ item.surname }}</b-col>
            </b-row>
            <b-row>
              <b-col v-if="item.contribId" cols="1"><b>ID:</b></b-col>
              <b-col v-if="item.contribId" cols="6">{{ item.contribId.text }}</b-col>
            </b-row>
            <b-row v-if="item['xref-aff']">
              <b-col cols="2"><b>Affiliations</b></b-col>
              <b-col v-for="(aff, index2) in item['xref-aff']" :key="index2" cols="1"> 
                {{ aff.rid }}
              </b-col>
            </b-row>
            <b-row v-if="item['xref-corresp']">
              <b-col cols="2"><b>Corresp</b></b-col>
              <b-col v-for="(corresp, index3) in item['xref-corresp']" :key="index3" cols="1"> 
                {{ corresp.rid }}
              </b-col>
            </b-row>
            <b-row v-if="item['xref-fn']">
              <b-col cols="2"><b>Foot Notes</b></b-col>
              <b-col v-for="(note, index2) in item['xref-fn']" :key="index2" cols="1"> 
                {{ note.rid }}
              </b-col>
            </b-row>
          </b-card>
        </span>
      </span>
    </b-card>
    <br>
    <b-card title="Affiliations">
      <span v-if="affiliates">
        <b-row v-for="(item, index) in affiliates" :key="index">
          <b-col cols="12"> 
            <b-card bg-variant="light" :title="item.id">
              {{ item.xml }}
            </b-card>
          </b-col>
        </b-row>
      </span>
    </b-card>
    <br>
    <b-card title="Author Notes">
      <span v-if="authorNotes">
        <b-row v-for="(item, index) in authorNotes" :key="index">
          <b-col cols="12"> 
            <b-card bg-variant="light" :title="item.attrs">
              {{ item.xml }}
            </b-card>
          </b-col>
        </b-row>
      </span>
    </b-card>
  </section>
</template>
 
<script>

import detailUtil from '@/views/pubmill/detailUtil'

import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'

export default {

  components: {
    BRow,
    BCol,
    BCard,
  }, 
 
  props: {
    article: {
      type: Object,
      default: null
    }, 
    articleSummary: {
      type: Object,
      default: null 
    }, 
    literatumSchematronErrors: {
      type: Array,
      default: null
    },
    genericSchematronErrors: {
      type: Array,
      default: null
    },   
  },
  data () {
    return {
      contributors: null,
      affiliates: null,
      authorNotes: null,
      mounted: null,
    }
  },
  computed: {
  
  },
  mounted () { 
    //console.log('aaaaaaaaaaaaaaaaaa', in article-detail-contributors)

    const contribGroup = detailUtil.contribGroupOn(this.article)
    this.authorNotes = contribGroup.authorNotes
    this.contributors = contribGroup.contributors
    this.affiliates = contribGroup.affiliates
    //console.log('aaaaaaaaaaaaaaaaaa', this.contributors)
    this.mounted = true
  },
  methods: {
  }
}
</script>

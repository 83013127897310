<template>
  <section> 
    <b-dropdown
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      text="Article Operations"
      variant="primary"
    >
      <b-dropdown-item v-if="inProduction">
        <span class="mr-1">
          <feather-icon
            icon="UploadCloudIcon"
            size="16"
          />
        </span>
        <a class="text-primary" @click="$bvModal.show(uploadModalId)"> Upload article files</a>  
      </b-dropdown-item>
      <b-dropdown-item>
        <span class="mr-1">
          <feather-icon
            icon="DownloadCloudIcon"
            size="16"
          />
        </span>
        <a class="text-primary" @click="$bvModal.show(downloadModalId)"> Download article zip</a>  
      </b-dropdown-item>
      <!--
      <b-dropdown-item>
        <span class="mr-1">
          <feather-icon
            icon="DownloadCloudIcon"
            size="16"
          />
        </span>
        <a class="text-primary" @click="getDownloadWordFromXML()"> Export Docx from XML</a>  
      </b-dropdown-item>
      -->
      <b-dropdown-item v-if="inProduction">
        <span class="mr-1 text-danger">
          <feather-icon
            icon="XCircleIcon"
            size="16"
            color="danger"
          />
        </span>
        <a class="text-danger" @click="removeArticle()">Remove all article files</a>
      </b-dropdown-item>
    </b-dropdown>
    
    <!-- modal for article download-->
    <b-modal
      :id="downloadModalId" 
      centered
      title="Download Article" 
      ok-title="Download"
      cancel-title="Cancel"
      size="xs"
      @ok="downloadArticle"
    >
      <h5>Resource types to download</h5>
      <b-form-checkbox
        v-model="downloadFileTypes"
        value="xml"
      >
        XML
      </b-form-checkbox>
      <b-form-checkbox
        v-model="downloadFileTypes"
        value="pdf"
      >
        PDF
      </b-form-checkbox>
      <b-form-checkbox
        v-model="downloadFileTypes"
        value="images"
      >
        Images
      </b-form-checkbox>
      <b-form-checkbox
        v-model="downloadFileTypes"
        value="supp"
      >
        Supplemental 
      </b-form-checkbox>
        
    </b-modal>

    <!-- modal for article upload-->
    <b-modal
      :id="uploadModalId" 
      centered
      ok-only
      ok-title="Close"
      title="Upload Article File" 
      size="lg"
    >
      <h5>Select the type and file to upload</h5>
      <b-form-select
        v-model="selectionTypeForUpload"
        :options="optionsForUpload"
      />
      <br><br>
      <span v-if="selectionTypeForUpload">
        <b-form-file 
          v-if="selectionTypeForUpload === 'img' || selectionTypeForUpload === 'supp'"
          v-model="multipleFilesToUpload"
          multiple
          :accept="extensionsForUploadType"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="OK to Drop"
          size="lg"
        />
        <b-form-file 
          v-else
          v-model="singleFileToUpload"
          :accept="extensionsForUploadType"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="OK to Drop"
          size="lg"
        />
        <br><br>
        <span v-if="!selectionTypeForUploadError && (singleFileToUpload || multipleFilesToUpload.length > 0)">
          <notes-and-notify ref="notesAndNotify" />
          <br><br>
          <b-button 
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="primary" 
            @click.stop="uploadFileRequest()"
          >
            <feather-icon
              icon="UploadCloudIcon"
              class="mr-50"
            /> 
            Upload
          </b-button>`
        </span>
      </span>
      <b v-if="selectionTypeForUploadError" class="text-danger">
        {{ selectionTypeForUploadError }}
      </b>
    </b-modal>
    <b-toast
      id="upload-complete-toast"
      auto-hide-delay="1000"
    >
      <b class="text-success"> Upload Finished</b>
    </b-toast>
  </section>
</template>
 
<script>

import fileMgrApi from '@/dl_pubmill/apis/fileMgr'
import publicationApi from '@/dl_pubmill/apis/publication'

import Ripple from 'vue-ripple-directive'
import {
  BButton, BFormCheckbox, BModal, BDropdown, BDropdownItem, BFormSelect, BFormFile, BToast
} from 'bootstrap-vue'

import NotesAndNotify from '../issue/NotesAndNotify.vue'

export default {

  components: {
    BButton,
    BFormCheckbox,
    BModal,
    BDropdown, 
    BDropdownItem,
    BFormSelect,
    BFormFile,
    BToast,
    NotesAndNotify,
  },
  directives: {
    Ripple,
  },
 
  props: {
    xmlFile: {
      type: String,
      default: null
    },   
    article: {
      type: Object,
      default: null
    }, 
  },
  data () {
    return {
      //downloadFileTypes: ['xml', 'pdf', 'images', 'supp']
      downloadFileTypes: ['xml', 'pdf'],
      selectionTypeForUpload: null,
      optionsForUpload: [
        { value: null, text: 'Please select file category' },
        { value: 'xml', text: 'XML' },
        { value: 'pdf', text: 'PDF' },
        { value: 'img', text: 'Image' },
        { value: 'suppl_manifest', text: 'Suppl Manifest' },
        { value: 'supp', text: 'Supplement' },
      ],
      multipleFilesToUpload: [],
      singleFileToUpload: null,
    }
  },
  computed: {
    
    selectionTypeForUploadError () {

      if (this.selectionTypeForUpload !== 'xml' && this.inProduction && !this.article.doi) {
        return 'DOI Not defined in XML file.'
      }
      if (this.singleFileToUpload) {
        if (this.selectionTypeForUpload === 'xml' && this.xmlFile !== this.singleFileToUpload.name) {
          return 'XML File name cannot be different than the original.'
        }
        if (this.selectionTypeForUpload === 'pdf') {
          const pdfPref =  this.singleFileToUpload.name.split('.pdf')[0]
          const xmlPref =  this.xmlFile.split('.xml')[0]
          if (xmlPref !== pdfPref) {
            return 'PDF file name must be the same as the XML file name.'
          }   
        }
      }
      return null  
    },
    inProduction () {
      return this.$route.path.includes('production-admin')
    },
    extensionsForUploadType () {
      if (this.selectionTypeForUpload === 'xml') {
        return '.xml'
      } 
      if (this.selectionTypeForUpload === 'pdf') {
        return '.pdf'
      }
      if (this.selectionTypeForUpload === 'img') {
        return '.tif'
      }
      if (this.selectionTypeForUpload === 'suppl_manifest') {
        return '.suppl'
      } 
      return null
    },
    downloadModalId () {
      return 'article-download-modal-'.concat(this.xmlFile)
    },
    uploadModalId () {
      return 'article-upload-modal-'.concat(this.xmlFile)
    },
    removeModalId () {
      return 'article-remove-modal-'.concat(this.xmlFile)
    },
  }, 
  watch: {
    selectionTypeForUpload () {
      console.log('selectionTypeForUpload changed', this.selectionTypeForUpload)
      this.multipleFilesToUpload = []
      this.singleFileToUpload = null
    },
  }, 
  methods: {
 
    updateAudit (changeActivityName) {
      console.log('changeActivityName', changeActivityName)
      
      let additionalNotes = this.$refs.notesAndNotify.additionalNotes
      if (additionalNotes !== null) {
        console.log('additionalNotes', additionalNotes)
        const tmp = additionalNotes.trim().replace(/\s+/g, '')
        if (tmp === '' || tmp === '<p></p>') {
          additionalNotes = null
        } 
      }
    
      //console.log('additionalNotes final ', additionalNotes)
      //console.log('uuuu', this.$refs.notesAndNotify.usersToNotify)
      const notesAndNotification = {
        additionalNotes,
        usersToNotify: this.$refs.notesAndNotify.usersToNotify
      }

      const dois = [this.article.doi]
  
      //console.log('staging ddd issueFileNames', issueFileNames, 'dois', dois)
      publicationApi.updateAudit(this.$route.params.issn, this.$route.params.vol, this.$route.params.issue, dois, [], changeActivityName, notesAndNotification, this, null)
      
    },
    
    backFromUpdateAudit (serverData) {
      if (serverData.error) {
        console.log('backFromUpdateAudit with error', serverData.error)
      }
      console.log('backFromUpdateAudit success')  
      this.$emit('RefreshDataRequested')
    }, 
    
    uploadFileRequest () {
      
      if (!this.inProduction) {
        console.log('error.  needs to be production')
        return
      }
      this.updateAudit('File Uploaded')
      if (this.singleFileToUpload && this.multipleFilesToUpload.length > 0) {
        console.log('error.  both singleFileToUpload and multipleFilesToUpload  cannot be populated')
      }
      let filesToUpload = null
      if (this.singleFileToUpload) {
        filesToUpload = [
          this.singleFileToUpload
        ]
      } else {
        filesToUpload = this.multipleFilesToUpload
      }
      this.multipleFilesToUpload = []
      this.singleFileToUpload = null
      
      //console.log('this.filesToUpload', filesToUpload.length, filesToUpload)
      for (let i = 0; i < filesToUpload.length; i++) {
        const queryParams = {
          issn: this.$route.params.issn,
          id_folder: this.article.doi,
          content_type: this.selectionTypeForUpload,
          rawFile: filesToUpload[i]

        }
        queryParams.file_status = 'production'
        queryParams.volume =  this.$route.params.vol
        queryParams.issue = this.$route.params.issue

        //let file = this.fileName.trim()
        //file = file.replace(/\s/g, '_')
        fileMgrApi.uploadFileRequest(queryParams, this, null) 
      }
    },
    backFromUploadFileRequest (serverData) {
      console.log('backFromUploadFileRequest', serverData)
      if (serverData.error) {
        console.log('serverData.error', serverData.error)
        this.$bvModal
          .msgBoxOk(serverData.error, {
            title: 'Sorry. There was a problem with the upload',
            size: 'sm',
            okVariant: 'danger',
            modalClass: 'modal-danger',
            okTitle: 'Ok',
          })
      } else { 
        this.$emit('RefreshDataRequested')
        this.$bvToast.show('upload-complete-toast')
      }
    },
    downloadArticle () {
      if (this.downloadFileTypes.length === 0) return
      this.getDownloadArticleLink()
    },

    getDownloadArticleLink () {
      const queryParams = {
        issn: this.$route.params.issn,
        types: this.downloadFileTypes,
        xml_file_name: this.xmlFile
      } 

      queryParams.volume =  this.$route.params.vol
      queryParams.issue = this.$route.params.issue
      if (this.$route.path.includes('production-admin')) {
        queryParams.file_status = 'production'
      } else {
        queryParams.file_status = 'published'
      }

      fileMgrApi.getDownloadArticleLink(queryParams, this, null)
    },

    backFromGetDownloadArticleLink (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        //console.log('zzzzzzzzzzzzzxxx', serverData)
        /* do this when server data is the fike blob and not a url
        const fileURL = window.URL.createObjectURL(new Blob([serverData]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'zzz.xml')
        document.body.appendChild(fileLink)
        fileLink.click() 
        */
        //since serverData  is a url  ( one that has a presigned s3 url )
        const fileURL = serverData
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        document.body.appendChild(fileLink)
        fileLink.click() 
      } 
    },
    getDownloadWordFromXML () {
      const queryParams = {
        issn: this.$route.params.issn,
        xml_file_name: this.xmlFile
      } 
      queryParams.volume =  this.$route.params.vol
      queryParams.issue = this.$route.params.issue
      if (this.$route.path.includes('production-admin')) {
        queryParams.file_status = 'production'
      } else {
        queryParams.file_status = 'published'
      }

      fileMgrApi.getDownloadWordFromXML(queryParams, this, null)
    },

    backFromGetDownloadWordFromXML (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        //console.log('zzzzzzzzzzzzzxxx', serverData)
        /* do this when server data is the fike blob and not a url
        const fileURL = window.URL.createObjectURL(new Blob([serverData]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'zzz.xml')
        document.body.appendChild(fileLink)
        fileLink.click() 
        */
        //since serverData  is a url  ( one that has a presigned s3 url )
        const fileURL = serverData
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        document.body.appendChild(fileLink)
        fileLink.click() 
      } 
    },
  }
}
</script>

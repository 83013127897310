<template>
  <section v-if="article"> 

    <div v-if="articleErrors">
      <b class="text-danger"> Errors</b>
      <div v-for="(item, index) in articleErrors" :key="index"> 
        <p class="text-danger">{{ item }}</p>
      </div>
    </div>
    <b-card title="Article Meta">
      <br>
      <b-row>
        <b-col v-for="(item, index) in article['article-meta']['article-id']" :key="index" cols="6"> 
          <b-row>
            <b-col cols="4"><b>{{ item['pub-id-type'] }}</b></b-col>
            <b-col cols="8">{{ item['value'] }}</b-col>
          </b-row>
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col v-for="(item, index) in article['article-meta']['pub-date']" :key="index" cols="6"> 
          <b-row>
            <b-col cols="4"><b>{{ item['publication-format'] }}</b></b-col>
            <b-col cols="8">{{ item['date'] }}</b-col>
          </b-row>
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col cols="2"><b>Volume</b></b-col>
        <b-col cols="4">{{ article['article-meta']['volume']['value'] }}</b-col>
        <b-col cols="2"><b>Issue</b></b-col>
        <b-col cols="4">{{ article['article-meta']['issue']['value'] }}</b-col>
      </b-row>
      <br>
      <b-row v-if="article['article-meta']['elocation-id']">
        <b-col cols="2"><b>E-Loc</b></b-col>
        <b-col cols="4">{{ article['article-meta']['elocation-id']['value'] }}</b-col>
      </b-row>
      <b-row v-if="article['article-meta']['fpage']">
        <b-col cols="2"><b>FPage</b></b-col>
        <b-col cols="4">{{ article['article-meta']['fpage']['value'] }}</b-col>
        <b-col v-if="article['article-meta']['lpage']" cols="2"><b>LPage</b></b-col>
        <b-col v-if="article['article-meta']['lpage']" cols="4">{{ article['article-meta']['lpage']['value'] }}</b-col>
      </b-row>
      <br>
      <b-row >
        <b-col  v-if="article['article-meta']['pdf']" cols="2"><b>PDF</b></b-col>
        <b-col v-if="article['article-meta']['pdf']" cols="4">{{ article['article-meta']['pdf']['value'] }}</b-col>
        <b-col v-if="article['article-meta']['page-count']" cols="2"><b>Page Count</b></b-col>
        <b-col v-if="article['article-meta']['page-count']" cols="4">{{ article['article-meta']['page-count']['value'] }}</b-col>
      </b-row>
      <span v-if="article['article-meta']['unsupported']">
        <br>
        <b-row>
          <b-col cols="2"><b>Unsupported</b></b-col>
          <b-col cols="10">{{ article['article-meta']['unsupported'] }}</b-col>
        </b-row>
      </span>
    </b-card>
    <br>
    <b-card title="Journal Meta">
      <b-row>
        <b-col cols="2"><b>Title</b></b-col>
        <b-col cols="10">{{ article['journal-meta']['journal-title'] }}</b-col>
      </b-row>
      <b-row>
        <b-col cols="2"><b>Abbreviated</b></b-col>
        <b-col cols="10">{{ article['journal-meta']['abbrev-journal-title'] }}</b-col>
      </b-row>
      <br>
      <span v-for="(item, index) in article['journal-meta']['journal-id']" :key="'ji' + index">
        <b-row>
          <b-col v-if="index == 0" cols="2"><b>Journal Type</b></b-col>
          <b-col v-else cols="2"></b-col>
          <b-col cols="3">{{ item['journal-id-type'] }}</b-col>
          <b-col cols="2"><b>Value</b></b-col>
          <b-col cols="3">{{ item['value'] }}</b-col>
        </b-row>
      </span>
      <br>
      <b-row v-for="(item, index) in article['journal-meta']['issn']" :key="'jm' + index">
        <b-col v-if="index == 0" cols="2"><b>ISSN Type</b></b-col>
        <b-col v-else cols="2"></b-col>
        <b-col cols="3">{{ item['pub-type'] }}</b-col>
        <b-col cols="2"><b>Value</b></b-col>
        <b-col cols="3">{{ item['value'] }}</b-col>
      </b-row>
      <br>
      <b-row v-if="article['journal-meta']['publisher']">
        <b-col cols="2"><b>Publisher</b></b-col>
        <b-col cols="10">{{ article['journal-meta']['publisher']['value'] }}</b-col>
      </b-row>
      <span v-if="article['journal-meta']['unsupported']">
        <br>
        <b-row >
          <b-col cols="2"><b>Unsupported</b></b-col>
          <b-col cols="10">{ article['journal-meta']['unsupported'] }}</b-col>
        </b-row>
      </span>
    </b-card>
  </section>
</template>
 
<script>

import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'

export default {

  components: {
    BRow,
    BCol,
    BCard,
  }, 
 
  props: {
    article: {
      type: Object,
      default: null
    }, 
    articleSummary: {
      type: Object,
      default: null 
    }, 
    literatumSchematronErrors: {
      type: Array,
      default: null
    },
    genericSchematronErrors: {
      type: Array,
      default: null
    },   
  },
  data () {
    return {
      knownTags: ['doi', 'title-group', 'pub-date:print', 'pub-date:electronic', 'volume', 'issue', 'elocation-id', 'self-uri'],
      otherTags: null,
    }
  },
  computed: {
    
    articleErrors () {
      //locationPrefix = "/article/front/article-meta/"
      let errors = null
      if (this.genericSchematronErrors != null) {
        for (let i = 0; i < this.genericSchematronErrors.length; i++) {
          if (this.isKnownTag(this.literatumSchematronErrors[i].location)) {
            if (errors == null) {
              errors = []
            }
            errors.push(this.genericSchematronErrors[i].message)
          }
        }
      }
      if (this.literatumSchematronErrors != null) {
        for (let i = 0; i < this.literatumSchematronErrors.length; i++) {
          if (this.isKnownTag(this.literatumSchematronErrors[i].location)) {
            if (errors == null) {
              errors = []
            }
            errors.push(this.literatumSchematronErrors[i].message)
          }
        }
      }
      return errors
    }, 
  },
  mounted () { 
    //console.log('this.literatumSchematronErrors', this.literatumSchematronErrors)
    //console.log('aaaaaaaaaaaaaaaaaa', this.articleSummary.relatedArticles)
  },
  methods: {
    isKnownTag (tag) {
      let knownTagFound = null
      for (let i = 0; i < this.knownTags.length; i++) {
        const knownTag =  this.knownTags[i]  
        let key = knownTag
        let secondary = null
        if (key.includes(':')) {
          secondary = key.split(':')[1]
          key = key.split(':')[0]
        }
        if (tag.includes(key) && (!secondary || tag.includes(secondary))) {
          knownTagFound = true
          break
        }
      }
      return knownTagFound
    }, 
  }
}
</script>
